import { createAction, props } from '@ngrx/store';

import { ChannelOptionsSettings } from '../../models';

export const loadRequest = createAction(
  '[Channel Options] Load Request',
  props<{ channelId: number }>(),
);

export const loadSuccess = createAction(
  '[Channel Options] Load Success',
  props<{ items: ChannelOptionsSettings[] }>(),
);

export const loadFailure = createAction(
  '[Channel Options] Load Failure',
  props<{ error: any }>(),
);

export const loadExtraOptionsRequest = createAction(
  '[Channel Options] Load Extra Options Request',
  props<{
    channelId: number;
    propertyId: number;
    fields: { [field: string]: any };
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const loadExtraOptionsSuccess = createAction(
  '[Channel Options] Load Extra Options Success',
  props<{ items: ChannelOptionsSettings[] }>(),
);

export const loadExtraOptionsFailure = createAction(
  '[Channel Options] Load Extra Options Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Channel Options] Reset State');
