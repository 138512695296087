import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ChannelOptionsStoreEffects } from './effects';
import { channelOptionsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('channelOptions', channelOptionsReducer),
    EffectsModule.forFeature([ChannelOptionsStoreEffects]),
  ],
})
export class ChannelOptionsStoreModule {}
